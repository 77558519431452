//ToDo: update this when the FdaHighlightsDto gets updated to match the labels api response

export type LabelKey = keyof LabelKeys;
export type OTCLabelKey = keyof OTCLabelkeys;
export interface LabelKeys {
  recentMajorChanges: number;
  indicationsAndUsage: number;
  dosageAndAdministration: number;
  dosageFormsAndStrengths: number;
  contraindications: number;
  warningsAndPrecautions: number;
  adverseReactions: number;
  drugInteractions: number;
  boxedWarning: number;
  clinicalPharmacology: number;
  clinicalStudies: number;
  howSupplied: number;
  useInSpecificPopulations: number;
  description: number;
  nonclinicalToxicology: number;
  instructionsForUse: number;
  mechanismOfAction: number;
  warnings: number;
}
export interface OTCLabelkeys {
  boxedWarning: number;
  warnings: number;
  otcActiveIngredient: number;
  otcPurpose: number;
  otcDoNotUse: number;
  otcAskDoctor: number;
  contraindications: number;
  otcAskDoctorPharmacist: number;
  otcWhenUsing: number;
  otcStopUse: number;
  otcPregnancyOrBreastFeeding: number;
  otcKeepOutOfReachOfChildren: number;
  otcQuestions: number;
}

export const MIN_ACCORDION_LENGTH = 6;

export const LABEL_ORDER: LabelKeys = {
  boxedWarning: 0,
  recentMajorChanges: 1,
  indicationsAndUsage: 2,
  dosageAndAdministration: 3,
  dosageFormsAndStrengths: 4,
  useInSpecificPopulations: 5,
  contraindications: 6,
  warningsAndPrecautions: 7,
  adverseReactions: 8,
  drugInteractions: 9,
  description: 10,
  clinicalPharmacology: 11,
  nonclinicalToxicology: 12,
  clinicalStudies: 13,
  howSupplied: 14,
  instructionsForUse: 15,
  mechanismOfAction: 16,
  warnings: 17,
};

export const OTC_LABEL_ORDER: OTCLabelkeys = {
  boxedWarning: 0,
  warnings: 1,
  otcPurpose: 2,
  otcActiveIngredient: 3,
  otcWhenUsing: 4,
  otcStopUse: 5,
  contraindications: 6,
  otcDoNotUse: 7,
  otcAskDoctor: 8,
  otcAskDoctorPharmacist: 9,
  otcPregnancyOrBreastFeeding: 10,
  otcKeepOutOfReachOfChildren: 11,
  otcQuestions: 12,
};
