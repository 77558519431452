import {Text} from '@prescriberpoint/ui';
import {FC, ReactNode} from 'react';

interface ServicesProps {
  pharmacyName: string;
  type: string;
  services: ReactNode[];
}

const Services: FC<ServicesProps> = ({type, pharmacyName, services}) => (
  <div className="flex flex-col rounded-lg bg-white p-4 shadow-lg">
    <Text
      className="mb-4"
      size="md">{`${pharmacyName} offers the following ${type} services:`}</Text>
    <ul>
      {services.map((service, idx) => (
        <li
          className="my-2 text-neutral-primary"
          key={pharmacyName + type + idx}>
          <Text as="body-md" size="md">
            {service}
          </Text>
        </li>
      ))}
    </ul>
  </div>
);

export default Services;
