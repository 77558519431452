import {Button, Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import {mockEnrollPSP} from '@/__mocks__/specialtyPharmacies';
import {useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';

const EnrollPSP: FC = () => {
  const {isMobileOrTablet} = useUserAgentContext();
  const {drugName} = useCurrentDrug();

  return !isMobileOrTablet ? (
    <div className="static py-6 md:sticky md:top-32 md:w-[30%] md:self-start">
      <div className="fixed bottom-0 w-full rounded-lg bg-neutral-lighter py-6 shadow-lg md:static md:bg-transparent">
        <div className="flex w-full flex-col space-y-2 px-6">
          <Text as="headline-sm">
            {`Enroll your patients in the ${drugName} Together program with just
            one click.`}
          </Text>
          <ul>
            {mockEnrollPSP.services.map((item, idx) => (
              <li
                className="my-2 text-neutral-primary"
                key={`psp-list-item-${idx}`}>
                <Text as="body-md" size="md">
                  {item}
                </Text>
              </li>
            ))}
          </ul>
          <Button className="!h-14 w-full" id="enroll_psp" stretched>
            {`Enroll in ${drugName} Savings Program`}
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default EnrollPSP;
