import {Link, Text} from '@prescriberpoint/ui';
import {FC, useMemo, useState} from 'react';
import {Section} from '@/components';
import {PHARMACIES_SECTION_IDS} from '@/constants/sectionIDs';
import {useCurrentPharmacy} from '@/hooks/useCurrentPharmacy';

const MIN_ACCORDION_LENGTH = 6;

const HealthPlans: FC = () => {
  const [accordionLength, setAccordionLength] = useState(MIN_ACCORDION_LENGTH);
  const {name, contracted_plans = []} = useCurrentPharmacy();

  const plans = useMemo(
    () => contracted_plans.slice(0, accordionLength),
    [accordionLength, contracted_plans],
  );

  const isAccordionCollapsed = accordionLength === MIN_ACCORDION_LENGTH;
  return contracted_plans?.length > 0 ? (
    <Section id={PHARMACIES_SECTION_IDS.healthPlans} title="Health plans">
      <Text>{name} services the following health plans:</Text>
      <div className="flex flex-col rounded-lg bg-neutral-lighter-alt px-4 shadow-lg">
        {plans.map((plan) => (
          <div
            key={plan}
            className="w-full border-0 border-b border-solid border-neutral-light py-4">
            <Text weight="regular">{plan}</Text>
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        {contracted_plans?.length > MIN_ACCORDION_LENGTH ? (
          <Link
            id="view_full_label_button"
            data-testid="view_full_label_button"
            onClick={() =>
              setAccordionLength(
                isAccordionCollapsed
                  ? contracted_plans?.length
                  : MIN_ACCORDION_LENGTH,
              )
            }
            label={isAccordionCollapsed ? 'View more' : 'View less'}
          />
        ) : null}
      </div>
    </Section>
  ) : null;
};

export default HealthPlans;
