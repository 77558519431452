import clsx, {ClassValue} from 'clsx';
import {FC} from 'react';
import DownloadButton from '../DownloadButton';
import {getPdfUrlFromFilename} from '@/utils';

export interface IActionButtonsProps {
  downloadFileName: string;
  downLoadFileUrl: string;
  className?: ClassValue;
}

const ActionButtons: FC<IActionButtonsProps> = ({
  downLoadFileUrl,
  downloadFileName,
  className,
}) => (
  <div className={clsx('flex w-full items-start justify-end', className)}>
    <DownloadButton
      primary
      fileName={downloadFileName}
      fileUrl={getPdfUrlFromFilename(downLoadFileUrl)}
    />
  </div>
);

export default ActionButtons;
