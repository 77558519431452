import {Button, customEvent, setVariable} from '@prescriberpoint/ui';
import dynamic from 'next/dynamic';
import {FC, useEffect, useState} from 'react';
import {CEVENT_AFFORDABILITY_FINDER} from '@/constants';
import {ENHANCED_SECTION_IDS} from '@/constants/sectionIDs';
import {useCoverageRestrictionsContext} from '@/context';
import {useCurrentDrug} from '@/hooks';
import {formatRetailPrice} from '@/utils/format';

const AssistanceProgramsModal = dynamic(
  () => import('./AssistanceProgramsModal'),
);

const FA_BTN_TEXT = 'Help Me Choose The Right Program';

export interface IFAProgramsProps {
  retailPrice?: number;
}

const FAPrograms: FC<IFAProgramsProps> = ({retailPrice}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {drugName} = useCurrentDrug();
  const {isAssistanceProgramsModalOpen, setIsAssistanceProgramsModalOpen} =
    useCoverageRestrictionsContext();

  const handleClick = () => {
    customEvent(CEVENT_AFFORDABILITY_FINDER, {
      drugName: drugName,
      retailValue: formatRetailPrice(retailPrice),
    });
    setIsModalOpen(true);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(searchParams.entries());
    const url = new URL(window.location.href);
    const anchorId = url.hash.substring(1);
    setVariable('faButtonName', FA_BTN_TEXT);
    if (
      queryParams.openModal &&
      anchorId === ENHANCED_SECTION_IDS.financialAssistance
    ) {
      setIsModalOpen(true);
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsAssistanceProgramsModalOpen(false);
  };

  return (
    <div className='flex w-full justify-center'>
      <Button
        className='sm:w-fit'
        id='fa_program'
        size='lg'
        stretched
        onClick={handleClick}>
        {FA_BTN_TEXT}
      </Button>
      {isModalOpen || isAssistanceProgramsModalOpen ? (
        <AssistanceProgramsModal
          isOpen={isModalOpen || isAssistanceProgramsModalOpen}
          closeModal={handleCloseModal}
        />
      ) : null}
    </div>
  );
};

export default FAPrograms;
