import {Text} from '@prescriberpoint/ui';
import {ResourceLink} from '@/components';
import {EnhancedContentLinkDto} from '@/models';

interface ResourceListProps {
  readonly resources: EnhancedContentLinkDto[];
}

export default function ResourceList({resources}: ResourceListProps) {
  return (
    <div className="flex flex-col space-y-2">
      <Text as="body-md" weight="bold">
        Forms
      </Text>
      <div className="flex flex-col rounded-lg shadow-lg">
        {resources.map((resource) => (
          <ResourceLink
            key={'resource-' + resource.title}
            resource={resource}
          />
        ))}
      </div>
    </div>
  );
}
