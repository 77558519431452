const MOST_VIEWED = {
  metaTitle: 'Most Viewed Medication Pages | PrescriberPoint',
  metaDescription:
    'This page provides a list of the most frequently visited pages on our website, featuring approved prescription drugs. As a doctor, this resource can be particularly practical for you since it allows for easy access to critical information about medications your patients may be taking. Each link directs you to a comprehensive resource dedicated to a specific prescription drug, including important details such as uses, dosages, and potential side effects, presented in an easy-to-understand format. We believe that this page can be a valuable tool for your practice and hope that you find it helpful in your daily efforts to provide excellent care to your patients',
  title: 'Most Viewed Medication Pages',
  drugDescriptions: [
    '{labelTitle} or {ingredients} is a medication used to treat {indications}. For more details on dosage and administration for {indications}. Visit the {labelTitle} resource page.',
    '{labelTitle} a drug in the {classes} class(es). Administration and dosing details along with more drug class information can be found on the {labelTitle} resource page.',
    '{labelTitle} is a {type} medication that contains the following ingredient(s) {ingredients}. Visit the {labelTitle} resource page for more information including contraindications.',
  ],
  bottomText:
    'This page provides a list of the most frequently visited pages on our website, featuring approved prescription drugs. As a doctor, this resource can be particularly practical for you since it allows for easy access to critical information about medications your patients may be taking. Each link directs you to a comprehensive resource dedicated to a specific prescription drug, including important details such as uses, dosages, and potential side effects, presented in an easy-to-understand format. We believe that this page can be a valuable tool for your practice and hope that you find it helpful in your daily efforts to provide excellent care to your patients',
};

const RESULTS_PER_PAGE = 1000;

export {MOST_VIEWED, RESULTS_PER_PAGE};
