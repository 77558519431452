import {Button} from '@prescriberpoint/ui';
import clsx from 'clsx';
import {FC, useEffect, useState} from 'react';
import {useFormResourcesContext} from '@/context';
import {toSnakeCase} from '@/utils/string';

interface DownloadButtonProps {
  fileUrl: string;
  fileName: string;
  className?: string;
}

const DownloadButton: FC<DownloadButtonProps> = ({
  fileUrl,
  fileName,
  className,
}) => {
  const [pdfUrl, setPdfUrl] = useState('');

  const {setIsEmailModalOpen, canDownload} = useFormResourcesContext();

  useEffect(() => {
    fetch(fileUrl).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        setPdfUrl(fileURL);
      });
    });
  }, [fileUrl]);

  const onDonwload = () => {
    if (!canDownload) {
      setIsEmailModalOpen(true);
    } else {
      const a = document.createElement('a');
      a.href = pdfUrl;
      a.download = `${fileName}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  return (
    <Button
      onClick={onDonwload}
      id={`download_${toSnakeCase(fileName)}`}
      className={clsx('relative w-full', className)}
      iconRightName="Download"
      showIconRight>
      Download
    </Button>
  );
};

export default DownloadButton;
