// Making this file a JS file because some conflicts with ts
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@/tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);

export function getColor(colorName) {
  return fullConfig.theme.colors[colorName];
}

export function getTheme() {
  return fullConfig.theme;
}
