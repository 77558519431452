import {Text} from '@prescriberpoint/ui';
import React, {FC} from 'react';
import ReactMarkdown from 'react-markdown';
import EnrollPSP from './EnrollPSP';
import HealthPlans from './HealthPlans';
import PharmacyHeader from './PharmacyHeader';
import ResourcesSection from './Resources';
import ServicesSection from './Services';
import {Section} from '@/components';
import {SPECIALTY_PHARMACIES_ENROLL_SECTION} from '@/constants/flags';
import {PHARMACIES_SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';
import {useCurrentDrug} from '@/hooks';
import {useCurrentPharmacy} from '@/hooks/useCurrentPharmacy';
import {PageContentLayout} from '@/layouts';
import {capitalize, replaceVariablesInStr} from '@/utils';

const SpecialtyPharmaciesPage: FC = () => {
  const {
    name,
    patient_services = [],
    pharma_services = [],
    payer_services = [],
    description = '',
    logo,
  } = useCurrentPharmacy();
  const {drugName} = useCurrentDrug();
  const showEnrollSection = useFlag(SPECIALTY_PHARMACIES_ENROLL_SECTION);

  return (
    <div className="w-full">
      <PageContentLayout>
        <div className="bg-neutral-lighter-alt px-4 pt-6 md:w-[70%] md:bg-white md:px-0">
          <Section id={PHARMACIES_SECTION_IDS.overview}>
            <PharmacyHeader logo={logo} />
            <div className="flex flex-col space-y-4 rounded-lg bg-white p-4 shadow-lg">
              <Text variant="base" size="md">
                <ReactMarkdown>
                  {replaceVariablesInStr(description, {drugName})}
                </ReactMarkdown>
              </Text>
            </div>
          </Section>
          <ResourcesSection />
          <HealthPlans />
          <ServicesSection
            id={PHARMACIES_SECTION_IDS.payerServices}
            title="Payer services"
            pharmacyName={capitalize(name)}
            type="payer"
            services={payer_services}
          />
          <ServicesSection
            id={PHARMACIES_SECTION_IDS.patientServices}
            title="Patient services"
            pharmacyName={capitalize(name)}
            type="patient"
            services={patient_services}
          />
          <ServicesSection
            id={PHARMACIES_SECTION_IDS.pharmaceuticalServices}
            title="Pharmaceutical services"
            pharmacyName={capitalize(name)}
            type="patient"
            services={pharma_services}
          />
        </div>
        {showEnrollSection && <EnrollPSP />}
      </PageContentLayout>
    </div>
  );
};

export default SpecialtyPharmaciesPage;
