import {Text} from '@prescriberpoint/ui';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {FC, useMemo} from 'react';
import {
  CEVENT_COMPARE_POPULAR_ALTERNATIVES,
  DlvComparePopularAlts,
} from '@/constants';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useEnhanced, useCompareIndicationDrugOptions} from '@/hooks';
import {getSectionTitleFromId} from '@/modules/ProductDetailPage/utils';
import {useEnhancedDrugs} from '@/queries';
import {customEvent} from '@/utils/gtm';

interface CompareLinksListProps {
  drug1: {drugName: string; setId: string; slug: string};
  drug2: {drugName: string; setId: string; slug: string};
}
const CompareLinksList: FC<CompareLinksListProps> = ({drug1, drug2}) => {
  const router = useRouter();
  const {data: enhancedDrugs = []} = useEnhancedDrugs();
  const {indication} = router.query;
  const {options} = useCompareIndicationDrugOptions(
    indication as string,
    drug1.slug,
    drug2.slug,
  );
  const {resources: drug1SampleResources} = useEnhanced(
    drug1.setId,
    SECTION_IDS.requestSamples,
  );
  const {resources: drug2SampleResources} = useEnhanced(
    drug2.setId,
    SECTION_IDS.requestSamples,
  );

  const popularAlternatives = useMemo(
    () =>
      options
        .filter(({slug}) => !!slug)
        .map((drug) => {
          const slugKey = drug.slug.split('-');
          const drug2Name =
            enhancedDrugs?.find((brand) => brand.setId.includes(slugKey[1]))
              ?.name ?? drug.label;

          return {
            title: `${drug1.drugName}® vs ${drug2Name}`,
            href: `/compare/${indication}/${drug1.slug}-vs-${drug.slug}`,
          };
        }),
    [drug1, options, indication, enhancedDrugs],
  );

  const pdpSections = [
    SECTION_IDS.summary,
    SECTION_IDS.drugLabel,
    SECTION_IDS.requestSamples,
    SECTION_IDS.priorAuthorization,
    SECTION_IDS.financialAssistance,
  ];

  const handleLinkClick = (title: string) => {
    customEvent<DlvComparePopularAlts>(CEVENT_COMPARE_POPULAR_ALTERNATIVES, {
      title,
    });
  };

  return (
    <div className='rounded-xl p-6 shadow-md'>
      <Text as='headline-sm' className='mb-3 block'>
        Popular Alternatives
      </Text>
      <ul className='mb-8 flex flex-col space-y-3'>
        {popularAlternatives.map((item, idx) => (
          <li key={item.title + idx} className='text-blue-700'>
            <Link
              onClick={() => handleLinkClick(item.title)}
              href={item.href}
              className='!font-medium !text-blue-700 !underline hover:!no-underline'>
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
      <Text as='headline-sm' className='!mb-5 block'>
        Relevant Resources
      </Text>
      <div className='mb-5 flex flex-col space-y-1'>
        <Text as='body-md' size='md'>
          {drug1.drugName}
        </Text>
        <ul className='flex flex-col space-y-3'>
          {pdpSections.map((section) =>
            section === SECTION_IDS.requestSamples &&
            drug1SampleResources?.length === 0 ? null : (
              <li key={section} className='text-blue-700'>
                <Link
                  href={`/therapies/${drug1.slug}#${section}`}
                  className='!font-medium capitalize !text-blue-700 !underline hover:!no-underline'>
                  {section === 'summary'
                    ? 'Dosage & Administration'
                    : getSectionTitleFromId(section)}
                </Link>
              </li>
            ),
          )}
        </ul>
      </div>
      <div className='flex flex-col space-y-1'>
        <Text as='body-md' size='md'>
          {drug2.drugName}
        </Text>
        <ul className='flex flex-col space-y-3'>
          {pdpSections.map((section) =>
            section === SECTION_IDS.requestSamples &&
            drug2SampleResources?.length === 0 ? null : (
              <li key={section} className='text-blue-700'>
                <Link
                  href={`/therapies/${drug2.slug}#${section}`}
                  className='!font-medium capitalize !text-blue-700 !underline hover:!no-underline'>
                  {section === 'summary'
                    ? 'Dosage & Administration'
                    : getSectionTitleFromId(section)}
                </Link>
              </li>
            ),
          )}
        </ul>
      </div>
      <Text as='headline-sm' className='mb-3 mt-8 block'>
        Other indications
      </Text>
      <Link
        className='!font-medium capitalize !text-blue-700 !underline hover:!no-underline'
        href='https://prescriberpoint.com/compare'>
        Compare drug alternatives
      </Link>
    </div>
  );
};

export default CompareLinksList;
