import {Text, IconButton, Icon} from '@prescriberpoint/ui';
import clsx from 'clsx';
import Link from 'next/link';
import {FC} from 'react';
import {useUserAgentContext} from '@/context';
import {useCurrentSlug} from '@/hooks';
import {Resource} from '@/models';
import {toSnakeCase} from '@/utils/string';

interface ResourceItemProps {
  link: Resource;
  className?: string;
}

const ResourceItem: FC<ResourceItemProps> = ({link, className}) => {
  const id = `${toSnakeCase(link.title)}_button`;
  const {slug} = useCurrentSlug();
  const {isClient} = useUserAgentContext();

  return (
    <div
      className={clsx(
        'relative border-b border-l-0 border-r-0 border-t-0 border-solid border-neutral-lighter bg-white first:rounded-t-lg last:rounded-b-lg last:border-b-0 hover:bg-neutral-lighter active:bg-neutral-light',
        className,
      )}>
      <div
        id={toSnakeCase(link.title)}
        className={clsx(
          'my-0 flex w-full items-center justify-between rounded-lg px-4 py-2 text-left text-neutral-primary hover:bg-neutral-lighter',
        )}>
        <span className="flex items-center">
          <span className="flex flex-col items-start" id="labelContainer">
            <Text
              as="body-sm"
              weight="bold"
              casing="title"
              variant={'base'}
              className="qr-title">
              {link?.title}
            </Text>
          </span>
        </span>
        <div className="flex items-center">
          <Link
            href={`/therapies/${slug}#${link.href}`}
            rel="noopener noreferrer"
            target="_blank">
            {isClient ? (
              <IconButton
                id={`${id}_action`}
                icon={
                  <Icon
                    className="h-6 w-6 rounded-full text-theme-primary"
                    name="OpenRegular"
                  />
                }
                tooltip="Open"
              />
            ) : null}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResourceItem;
