import {FC, ReactNode} from 'react';
import Services from './Services';
import {Section} from '@/components';

interface ServicesProps {
  pharmacyName: string;
  type: string;
  services: ReactNode[];
  id: string;
  title: string;
}

const ServicesSection: FC<ServicesProps> = ({
  id,
  title,
  type,
  pharmacyName,
  services,
}) =>
  services?.length > 0 ? (
    <Section id={id} title={title}>
      <Services pharmacyName={pharmacyName} type={type} services={services} />
    </Section>
  ) : null;

export default ServicesSection;
