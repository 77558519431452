import {RefObject, useEffect, useState} from 'react';
import {type Height} from 'react-animate-height';
import {useToggle} from 'usehooks-ts';

/**
 * Handles containers that may or not shrink/expand the height using a view more button
 * @param ref - The ref of the container
 * @param defaultHeight - The default height of the container in px
 * */

export default function useViewMore(
  ref: RefObject<HTMLElement>,
  defaultHeight: number,
) {
  const [height, setHeight] = useState<Height>(defaultHeight);
  const [textClamped, toggleClamp] = useToggle(true);
  const [showClampButton, setShowClampButton] = useState(false);

  function toggleHeight() {
    setHeight(textClamped ? 'auto' : defaultHeight);
    toggleClamp();
  }

  useEffect(() => {
    if (ref.current) {
      if (ref.current.clientHeight > defaultHeight) {
        setShowClampButton(true);
      } else {
        setHeight('auto');
        setShowClampButton(false);
      }
    }
  }, [ref, defaultHeight]);

  return {height, toggleHeight, showClampButton, textClamped};
}
