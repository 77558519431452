import {useRouter} from 'next/router';
import {PharmacyDetailsDto} from '@/models';
import {usePharmacy} from '@/queries/usePharmacy';

export function useCurrentPharmacy(useTempIndex?: boolean): PharmacyDetailsDto {
  const router = useRouter();
  const {pharmacySlug} = router.query;
  const {data: pharmacy} = usePharmacy(
    pharmacySlug as string,
    {},
    useTempIndex,
  );

  if (!pharmacy) {
    return {
      name: '',
      address: '',
      patient_services: [],
      pharma_services: [],
      contracted_plans: [],
      objectID: '',
      description: '',
      phone: '',
      email: '',
      payer_services: [],
      distribution: 'unknown',
      logo: '',
      limited_brands: [],
      exclusive_brands: [],
    };
  }
  return pharmacy;
}
