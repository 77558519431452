import {EXAMPLE_PROMPTS, MAX_RANDOM_SUGGESTED_PROMPTS} from './constants';

export function buildSuggestedPrompts(drugsName: string) {
  if (drugsName) {
    return `What are the indications for ${drugsName}?,
  How do you store ${drugsName}?,
  What are the dosage forms of ${drugsName}?,
  Is ${drugsName} approved for pregnant women?,
  What is the generic name of ${drugsName}?`;
  }
  return getRandomSuggestedPrompts().join(',');
}

function getRandomSuggestedPrompts() {
  const randInt = Math.floor(Math.random() * EXAMPLE_PROMPTS.length);
  const examples = Array.from(
    {length: MAX_RANDOM_SUGGESTED_PROMPTS},
    (_, i) => EXAMPLE_PROMPTS[(i + randInt) % EXAMPLE_PROMPTS.length],
  );
  return examples;
}
