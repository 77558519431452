import {Text} from '@prescriberpoint/ui';
import Link from 'next/link';
import {FC} from 'react';
import {IDocument} from '@/models';
import {formatISOtoString, getLastVerifiedDate} from '@/utils/date';

export interface IResourceItemProps {
  resource: IDocument;
  path: string;
}

const ResourceItem: FC<IResourceItemProps> = ({resource, path}) => (
  <Link
    className="flex flex-col rounded-md border border-solid border-neutral-light bg-white px-2 py-4 no-underline"
    href={path}>
    <Text as="body-xs" weight="bold" casing="caps" variant="secondary">
      {`Last verified: ${formatISOtoString(
        getLastVerifiedDate(resource.lastUpdatedDate),
      )}`}
    </Text>
    <Text weight="semibold" className="truncate">
      {resource.referenceName || resource.filename}
    </Text>
  </Link>
);

export default ResourceItem;
