const INDICATION_TEMPLATE = {
  metaTitle: 'List of medications for {indicationName} | PrescriberPoint',
  metaDescription:
    'Information about treatments for {indicationName}. Prior authorizations, patient financial assistance, sample requests, support, and drug research.',
  title: 'Treatments for {indicationName}',
  descriptions:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius ut aliquet vitae pharetra ut. Posuere ut faucibus ligula erat ac tellus tincidunt lectus egestas. At viverra nec diam blandit augue cursus amet, elit eget. Viverra malesuada pellentesque erat sem ullamcorper. Pretium feugiat nibh eget in imperdiet. Magna justo ut ultrices sed sed. Pellentesque quam erat volutpat faucibus. Sapien ullamcorper at morbi mauris facilisi neque. Rhoncus sollicitudin lectus pellentesque arcu ut morbi. Urna, nullam ipsum nullam potenti amet, est vulputate at ac. Sagittis egestas egestas metus arcu sed quam eu. Etiam volutpat morbi eget non. Arcu fames hendrerit et aliquam in lobortis gravida. Sed quis aenean et gravida vestibulum nulla.',
  drugDescriptions: [
    '{labelTitle} or {ingredients} is a medication used to treat {indications}. For more details on dosage and administration for {indications}. Visit the {labelTitle} resource page.',
    '{labelTitle} a drug in the {classes} class(es). Administration and dosing details along with more drug class information can be found on the {labelTitle} resource page.',
    '{labelTitle} is a {type} medication that contains the following ingredient(s) {ingredients}. Visit the {labelTitle} resource page for more information including contraindications.',
  ],
};

const INDICATION_LANDING_PAGES = [
  'Atopic Dermatitis',
  'Angioedema',
  'Psoriasis',
  'Psoriatic Arthritis',
  'Rosacea',
];

const RESULTS_PER_PAGE = 1000; // 1000 is the maximum. https://www.algolia.com/doc/api-reference/api-parameters/hitsPerPage/#usage-notes

export {INDICATION_TEMPLATE, INDICATION_LANDING_PAGES, RESULTS_PER_PAGE};
