import {IPageSection} from '@/interfaces';

export const LABEL_SECTION: IPageSection = {
  title: '{brandName} Prescribing Information',
  description: {
    standard:
      'We receive information directly from the FDA and PrescriberPoint is updated as often as changes are made available.',
    enhanced:
      'We receive information directly from the FDA and PrescriberPoint is updated as often as changes are made available.',
  },
  sections: [],
};
