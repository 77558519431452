import {PhotoUpload} from '@prescriberpoint/ui';
import {FC, useState} from 'react';

export interface IRepPhotoUploadProps {
  nameInitials: string;
}

const RepPhotoUpload: FC<IRepPhotoUploadProps> = ({nameInitials}) => {
  const [selectedPhoto, setSelectedPhoto] = useState<File>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];
    setSelectedPhoto(fileUploaded);
  };

  // ToDo: add upload functionality when endpoint is ready
  const handleUpload = (imgBlob: string) => {
    console.log('imgBlob', imgBlob);
  };

  const currentTitle = selectedPhoto
    ? 'Profile picture'
    : 'Add your profile photo';
  const currentDescription = selectedPhoto
    ? 'Click and drag on the image to crop your picture.'
    : 'Make a good first impression and build trust with the healthcare community.';

  return (
    <PhotoUpload
      selectedPhoto={selectedPhoto}
      title={currentTitle}
      description={currentDescription}
      handleChange={handleChange}
      onUpload={handleUpload}
      nameInitials={nameInitials}
    />
  );
};

export default RepPhotoUpload;
