import {Text} from '@prescriberpoint/ui';

export interface ProgramTitleCardProps {
  title: string;
  detail: string;
  size?: 'md' | 'lg';
  disabled?: boolean;
}

const ProgramTitleCard = ({
  title,
  detail,
  disabled,
  size = 'md',
}: ProgramTitleCardProps) => (
  <div className="flex flex-col space-y-2">
    <Text
      as={size === 'md' ? 'headline-sm' : 'headline-md'}
      casing="title"
      variant={disabled ? 'tertiary' : 'base'}>
      {title}
    </Text>
    {!disabled ? (
      <Text as={size === 'md' ? 'title-md' : 'title-lg'} variant="primary">
        {detail}
      </Text>
    ) : null}
  </div>
);

export default ProgramTitleCard;
