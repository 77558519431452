import {
  Page,
  View,
  Document,
  StyleSheet,
  Image as PdfImage,
  Text,
} from '@react-pdf/renderer';
import {FC, useEffect, useState} from 'react';
import {getColor} from '@/utils/tailwind';

interface QrCodeDocumentProps {
  id: string;
  practiceName?: string;
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: getColor('theme-lighter-alt'),
    position: 'relative',
  },
  content: {
    padding: '60px 100px',
  },
  section: {
    textAlign: 'center',
  },
  title: {
    color: getColor('theme-primary'),
    fontSize: 16,
    textTransform: 'uppercase',
    marginBottom: 12,
    lineHeight: 1.33,
  },
  subtitle: {
    color: getColor('neutral-primary'),
    fontWeight: 'semibold',
    fontSize: 24,
    lineHeight: 1.33,
  },
  qr: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  qrTitle: {
    padding: '24px 0',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: getColor('neutral-primary'),
    fontSize: 25.139,
    fontWeight: 'bold',
    color: '#FFF',
    width: '100%',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
  },
  qrContainer: {
    padding: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF',
    borderBottomLeftRadius: '24px',
    borderBottomRightRadius: '24px',
    width: '100%',
  },
  qrImage: {
    width: 267.572,
    height: 267.572,
    margin: '0 auto',
  },
  footer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 22,
    width: 320,
    margin: '0 auto',
    marginTop: 32,
  },
  logo: {
    margin: '0 auto',
    width: 272,
  },
  footerContent: {
    display: 'flex',
    width: '100%',
    gap: 4,
  },
  footerText: {
    fontSize: 18.85,
    color: getColor('neutral-primary'),
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  bottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 260,
    width: '100%',
    backgroundColor: getColor('theme-lighter'),
    zIndex: -1,
  },
});

const QrCodeDocument: FC<QrCodeDocumentProps> = ({id}) => {
  const [dataUrl, setDataUrl] = useState('');

  useEffect(() => {
    const svg = document.getElementById(id);

    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg as Node);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL('image/png');
        setDataUrl(pngFile);
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <View style={styles.section}>
            <Text style={styles.title}>Scan to get started</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}>
              Our practice manages all sample needs through PrescriberPoint
            </Text>
          </View>
          <View style={styles.qr}>
            <Text style={styles.qrTitle}>Pharma Rep Check In</Text>
            <View style={styles.qrContainer}>
              <PdfImage src={dataUrl} style={styles.qrImage} />
            </View>
          </View>
          <View style={styles.footer}>
            <PdfImage
              style={styles.logo}
              src="https://cdn.prescriberpoint.com/assets/images/logo.png"
            />
            <View style={styles.footerContent}>
              <Text style={[styles.footerText, styles.bold]}>
                Need Support? Call (617) 286-4232
              </Text>
              <Text style={styles.footerText}>prescriberpoint.com</Text>
            </View>
          </View>
        </View>
        <View style={styles.bottom} />
      </Page>
    </Document>
  );
};

export default QrCodeDocument;
