import {PharmacyByBrandDto} from '@/models/pharmacyDto';

export const mockPatientServices = [
  "Protocol-driven patient clinical programs that support the patient's care",
  'Prior authorization across benefits',
  "Bridging pharmacy and medical to enhance communication regarding patient's treatment",
  'Adherence monitoring and support programs; Integrated pharmacy and medical specialty drug management',
];

export const mockPayerServices = [
  "Protocol-driven patient clinical programs that support the patient's care",
  'Prior authorization across benefits',
  "Bridging pharmacy and medical to enhance communication regarding patient's treatment",
  'Adherence monitoring and support programs; Integrated pharmacy and medical specialty drug management',
];

//TODO: TBD how the link is going to be in the JSON
export const mockPharmaServices = [
  'Financial assistance coordination with manufacturers and foundations',
  'Attempt to reduce out-of-pocket costs through financial/copay assistance from manufacturers or foundations See Taltz Copay Program',
];

export const mockPharmacyDescription = (
  <>
    Acaria Specialty pharmacy is an accredited pharmacy that specializes in
    providing personalized care to patients with complex or chronic health
    conditions. The pharmacy is accredited by several organizations, including
    URAC, ACHC, and The Joint Commission, which ensures that they meet high
    standards for quality and safety.
    <br /> <br />
    Acaria Specialty pharmacy can help patients by providing assistance with
    getting the drug Taltz. The pharmacy has a dedicated Taltz Access Program
    that helps patients navigate the insurance and financial aspects of
    accessing the medication. This program provides support with prior
    authorizations, appeals, and copay assistance, as well as offering free
    home delivery of medications.
    <br /> <br />
    In addition to Taltz support, Acaria Specialty pharmacy offers a wide range
    of services to support patients with their medication therapy. These
    services include:
    <br /> <br />
    <ul>
      <li> Medication counseling and education</li>
      <li> Medication adherence support</li>
      <li>Refill reminders and automatic prescription renewals</li>
      <li>Coordination with healthcare providers</li>
      <li>Clinical monitoring and support</li>
      <li>Specialty infusion services</li>
    </ul>
    <br />
    Acaria Specialty pharmacy is committed to providing personalized,
    high-quality care to patients with complex or chronic health conditions.
    Their Taltz Access Program and comprehensive range of services make them a
    valuable partner for healthcare providers seeking to support their
    {" patients'"} medication therapy.
  </>
);

export const mockEnrollPSP = {
  imageUrl: 'https://cdn.prescriberpoint.com/assets/images/taltztogether.png',
  services: [
    'Provides additional resources and support to patients starting or receiving treatment with Taltz',
    'Offers access to educational materials, personalized support from registered nurses, and assistance with insurance and financial resources',
    'Helps improve patient adherence to treatment plan, which is critical for achieving optimal clinical outcomes',
    'Provides regular reminders to take medication and resources to manage potencial side effects',
    'Helps patients feel more connected to their treatment plan and healthcare provider, which may increase patient satisfaction and improve overall quality of care',
  ],
};

export const mockPharmacyDetails: PharmacyByBrandDto = {
  phoneNumber: '888-321-0987',
  address: '6923 Lee Vista Blvd Ste 200, Orlando, Florida 3822',
  logo: 'https://cdn.prescriberpoint.com/assets/images/acariahealth.svg',
  distribution: 'exclusive',
  name: 'Acaria Health Pharmacy',
  limitedAndExclusiveDrugs: 37,
  healthPlans: 12,
  slug: 'acaria-health-pharmacy',
};

export const mockPharmacyPlans = [
  'Aetna',
  'BCBSMA',
  'Buckeye Health Plan',
  'Centene, Peach State Health Plan',
  'Sunshine Health, UnitedHealthcare (select)',
  'Wellcare',
  'Aetna',
  'BCBSMA',
  'Buckeye Health Plan',
  'Centene, Peach State Health Plan',
  'Sunshine Health, UnitedHealthcare (select)',
  'Wellcare',
];
