import {
  BottomSheet,
  Icon,
  IconButton,
  Loader,
  Modal,
  Text,
} from '@prescriberpoint/ui';
import {FC, useMemo} from 'react';
import RepRegistryQr from '@/components/RepRegistryQr';
import {PROTOCOL_HOSTNAME} from '@/constants/global';
import {useUserAgentContext} from '@/context';
import useUserData from '@/hooks/useUserData';
import {useLocation} from '@/queries';

export interface IRepRegistryPanelProps {
  showPanel: boolean;
  handleClose: () => void;
}

const RepRegistryPanel: FC<IRepRegistryPanelProps> = ({
  showPanel,
  handleClose,
}) => {
  const {userData, setUserData} = useUserData();
  const {data, isLoading, isError} = useLocation();

  const repCheckinUrl = data?.locationId
    ? `${PROTOCOL_HOSTNAME}/reps/checkin?locationId=${data?.locationId}`
    : '';

  const {isMobileOrTablet} = useUserAgentContext();

  const renderContent = (platform: 'mobile' | 'desktop') =>
    repCheckinUrl ? (
      <RepRegistryQr
        url={repCheckinUrl}
        show={userData.showRepQrInstructions}
        setShow={(value: boolean) => {
          setUserData((prevValue) => ({
            ...prevValue,
            showRepQrInstructions: value,
          }));
          handleClose();
        }}
        platform={platform}
      />
    ) : (
      <Loader />
    );

  const showDesktopModal = useMemo(
    () => showPanel && !isLoading && !isError && !isMobileOrTablet,
    [showPanel, isLoading, isError, isMobileOrTablet],
  );

  const showMobileModal = useMemo(
    () => showPanel && !isLoading && !isError && isMobileOrTablet,
    [showPanel, isLoading, isError, isMobileOrTablet],
  );

  return isMobileOrTablet ? (
    <BottomSheet
      onClose={handleClose}
      isOpen={showMobileModal}
      detent="content-height"
      header={
        <div className="flex justify-between">
          <Text variant="base" as="title-lg">
            Create rep registry
          </Text>
          <IconButton
            size="sm"
            onClick={handleClose}
            id="cancel_modal"
            icon={
              <Icon
                name="Cancel"
                className="flex h-4 w-4 text-neutral-primary"
              />
            }
          />
        </div>
      }>
      {renderContent('mobile')}
    </BottomSheet>
  ) : (
    <Modal
      closeModal={handleClose}
      position="side"
      title="Create rep registry"
      isOpen={showDesktopModal}
      showCancel={false}
      showConfirm={false}>
      {renderContent('desktop')}
    </Modal>
  );
};

export default RepRegistryPanel;
