export const HCP_FEATURES = [
  {
    title: 'Sample Ordering',
    description:
      'Get samples delivered to your doorstep with just a few clicks.',
    icon: 'ShoppingBasket',
  },
  {
    title: 'Prior Authorization Forms',
    description:
      'Enter the state, payer & drug to receive the most relevant forms.',
    icon: 'PriorAuthorizationForms',
  },
  {
    title: 'Coverage Restrictions',
    description:
      'View detailed instructions on how to navigate step therapy requirements.',
    icon: 'CoverageRestrictions',
  },
  {
    title: 'Dosing & Administration',
    description: 'View pediatric and adult dosing schedules.',
    icon: 'DosignAndAdministration',
  },
  {
    title: 'Drug-to-Drug Interactions',
    description: 'Check for drug-to-drug interactions or contraindications.',
    icon: 'PracticeNotes',
  },
];

export const REP_FEATURES = [
  {
    title: 'Connect with Doctors',
    description: "Link your profile to providers' sample stores.",
    icon: 'ConnectWithDoctors',
  },
  {
    title: 'Represent Your Brands',
    description:
      'Select the drug samples you can supply to populate a provider’s sample store.',
    icon: 'RepresentBrands',
  },
  {
    title: 'Book Appointments',
    description: "Manage sample requests from doctors you're connected with.",
    icon: 'BookAppointments',
  },
  {
    title: 'Deliver Samples',
    description: 'Meet with the provider that requested your products.',
    icon: 'DeliverSamples',
  },
];
