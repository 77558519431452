import {compareAsc} from 'date-fns';
import {RepDto} from '@/models';

export function formatPhoneNumber(phoneNumber: string) {
  let cleaned = ('' + phoneNumber).replace(/\D/g, '');
  if (cleaned?.length === 11 && cleaned[0] === '1') {
    cleaned = cleaned.substring(1);
  }
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '('
      .concat(match[1])
      .concat(') ')
      .concat(match[2])
      .concat('-')
      .concat(match[3]);
  }
  return phoneNumber;
}

//! This is temporary until data in the backend is cleaned up. This shold be removed
const roles_map: {[label: string]: string} = {
  sales_representative: 'Sales Rep',
  field_reimbursement_manager: 'Field Reimbursement Manager',
  medical_science_liaison: 'Medical Science Liaison',
  other: 'Other',
};

export function formatRep(rep: RepDto) {
  const {firstName = '', lastName = '', role, ...rest} = rep;
  const name = firstName && lastName ? `${firstName} ${lastName}` : 'Brand Rep';
  const nameInitials =
    firstName && lastName ? `${firstName[0]}${lastName[0]}` : 'NA';
  const roleCured = role && roles_map?.[role] ? roles_map[role] : role;
  return {
    ...rest,
    role: roleCured,
    phoneNumber: formatPhoneNumber(rep.phoneNumber as string),
    name,
    nameInitials,
  };
}

export const formatRepsList = (reps: RepDto[]) =>
  reps
    .map(formatRep)
    .sort((a, b) =>
      a.lastCheckIn && b.lastCheckIn
        ? compareAsc(new Date(a.lastCheckIn), new Date(b.lastCheckIn))
        : -1,
    );

export const formatRetailPrice = (price?: number | string | null) => {
  if (!price && price !== 0) return null;

  const parsedPrice = Number.parseFloat(price as string);
  return isNaN(parsedPrice) ? null : parsedPrice;
};
