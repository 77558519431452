import {Button} from '@prescriberpoint/ui';
import {FC} from 'react';
import ResourceItem from './ResourceItem';
import {Section} from '@/components';
import {PHARMACIES_SECTION_IDS, SECTION_IDS} from '@/constants/sectionIDs';
import {useCurrentDrug} from '@/hooks';

interface ResourcesProps {}

const RESOURCES = [
  {
    title: 'Dosage and Administration',
    href: SECTION_IDS.summary,
  },
  {
    title: 'Drug label',
    href: SECTION_IDS.drugLabel,
  },
  {
    title: 'Samples',
    href: SECTION_IDS.requestSamples,
  },
  {
    title: 'Prior authorization',
    href: SECTION_IDS.priorAuthorization,
  },
  {
    title: 'Pharmacy',
    href: SECTION_IDS.pharmacy,
  },
  {
    title: 'Patient Education',
    href: SECTION_IDS.patientEducation,
  },
];

const ResourcesSection: FC<ResourcesProps> = () => {
  const {drugName, slug} = useCurrentDrug();

  return (
    <Section
      id={PHARMACIES_SECTION_IDS.resources}
      title={`${drugName} resources`}>
      <div className="flex flex-col rounded-lg shadow-lg">
        <div className="border-0 border-b border-neutral-lighter">
          {RESOURCES.map((resource, idx) => (
            <ResourceItem key={idx} link={resource} />
          ))}
        </div>
      </div>
      <div className="flex justify-center">
        <Button
          id="view_drug_details"
          asLink
          href={`/therapies/${slug}`}
          className="!h-14">
          {`View ${drugName} Details`}
        </Button>
      </div>
    </Section>
  );
};

export default ResourcesSection;
