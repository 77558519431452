import {useMemo} from 'react';
import {FINANCIAL_ASSISTANCE_SUPPORT_SECTIONS} from '@/data/financialAssistanceSupport';
import {getAllSectionResources} from '@/hooks';
import {EnhancedContentLinkDto, RecentDocumentDto} from '@/models';

export default function useFinancialAssistanceResources(
  resources: (EnhancedContentLinkDto | RecentDocumentDto)[],
  sectionId: string,
) {
  return useMemo(() => {
    const section = FINANCIAL_ASSISTANCE_SUPPORT_SECTIONS.find(
      (item) => item.sectionId === sectionId,
    );
    return section
      ? getAllSectionResources(resources as EnhancedContentLinkDto[], section)
      : [];
  }, [resources, sectionId]);
}
