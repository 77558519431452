import {Button, Chip, Text} from '@prescriberpoint/ui';
import Image from 'next/image';
import {FC} from 'react';
import ContactField from '@/components/RepContactCard/ContactField';
import {SPECIALTY_PHARMACIES_LOGO} from '@/constants/flags';
import {useFlag, useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';
import {useCurrentPharmacy} from '@/hooks/useCurrentPharmacy';

interface PharmacyHeaderProps {
  logo?: string;
}

const PharmacyHeader: FC<PharmacyHeaderProps> = ({logo}) => {
  const {drugName} = useCurrentDrug();
  const {name, address, phone = '', distribution} = useCurrentPharmacy();

  const {isMobileOrTablet} = useUserAgentContext();
  const showLogo = useFlag(SPECIALTY_PHARMACIES_LOGO);

  // ! is neccesary due to chip default styles
  const distributionColor = {
    ['exclusive']: '!bg-blue-600',
    ['limited']: '!bg-gold-500',
    ['unknown']: '!bg-neutral-tertiary-alt',
  };

  return (
    <div className="flex w-full flex-col items-center">
      <Text
        tag="h1"
        as={isMobileOrTablet ? 'headline-lg' : 'headline-xl'}
        casing="title"
        weight="bold">
        {drugName}
      </Text>
      <Text
        className="text-center"
        tag="h1"
        as={isMobileOrTablet ? 'headline-lg' : 'headline-xl'}
        casing="title">
        {name}
      </Text>
      <div className="my-6 space-y-6">
        <div className="flex flex-col items-center justify-center space-y-3 md:flex-row md:space-x-3 md:space-y-0">
          {showLogo && logo && (
            <Image src={logo} alt="logo" width={113} height={35} />
          )}
          {distribution && (
            <Chip
              label={`${distribution} distribution`}
              size="md"
              casing="title"
              className={distributionColor[distribution]}
            />
          )}
        </div>
        <div className="flex flex-col items-center">
          <Text variant="secondary">Address</Text>
          <Text>{address}</Text>
        </div>
        {phone && (
          <div className="flex flex-col items-center">
            {!isMobileOrTablet && <Text variant="secondary">Phone</Text>}
            {isMobileOrTablet ? (
              <Button
                asLink
                stretched
                id="call"
                size="lg"
                href={`tel:${phone}`}>
                Call
              </Button>
            ) : (
              <ContactField
                as="body-md"
                variant="base"
                href={`tel:${phone}`}
                value={phone}
                name="number"
              />
            )}
          </div>
        )}
      </div>
      <div className="flex w-full justify-center border-0 border-t border-solid border-neutral-quaternary-alt pt-2" />
    </div>
  );
};

export default PharmacyHeader;
