import {Icon, Text, Paper} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';

interface IFeatureCardProps {
  icon: string;
  title: string;
  description: string;
  className?: ClassValue;
}

const FeatureCard: React.FunctionComponent<IFeatureCardProps> = ({
  icon,
  title,
  description,
  className,
}) => (
  <Paper
    elevation="lg"
    className={clsx(
      'm-3 flex w-64 shrink-0 flex-col items-start space-y-6 rounded-lg px-4 py-6 md:flex-wrap md:px-6 md:py-14',
      className,
    )}>
    <div className="flex flex-col items-start justify-start space-y-6">
      <div className="relative h-[46px] w-[60px]">
        <Icon name={icon} />
      </div>
      <Text as="headline-sm" tag="h3">
        {title}
      </Text>
    </div>
    <Text size="md" weight="regular">
      {description}
    </Text>
  </Paper>
);

export default FeatureCard;
