export const MAX_RANDOM_SUGGESTED_PROMPTS = 5;

export const EXAMPLE_PROMPTS = [
  'What are the indications for Xeljanz',
  'Is Dupixent FDA approved for asthma?',
  'Is Cosentyx approved for pediatric use?',
  'What is the pediatric dosing for Cosentyx?',
  'What is the renal impairment dosage for Lorbrena?',
  'How do I store Emgality?',
  'What is the dosage for Mounjaro?',
  'Is Vyepti self-administered?',
  'What are the dosage forms for Xeljanz?',
  'Is Nurtec used to prevent migraines?',
];