import {Text, Icon} from '@prescriberpoint/ui';
import clsx from 'clsx';
import ProgramTitleCard from '../ProgramTitleCard';
import ResourceList from './ResourceList';
import {List} from '@/components';
import {SHOW_V2_PPT_DESIGN} from '@/constants/flags';
import {useFlag} from '@/context';
import useFinancialAssistanceResources from '@/hooks/useFinancialAssistance';
import {EnhancedContentLinkDto, RecentDocumentDto} from '@/models';

export interface IAvailableProgram {
  name: string;
  icon: string;
}

export interface ProgramSectionProps {
  title: string;
  headline: string;
  programs?: IAvailableProgram[];
  details?: string[];
  requirements?: string[];
  resources: Array<EnhancedContentLinkDto | RecentDocumentDto>;
  sectionId: string;
}

const ProgramSection = ({
  title,
  headline,
  programs,
  details = [],
  requirements = [],
  resources,
  sectionId,
}: ProgramSectionProps) => {
  const sectionResources = useFinancialAssistanceResources(
    resources,
    sectionId,
  );

  const showNewDesign = useFlag(SHOW_V2_PPT_DESIGN);

  if (sectionResources.length === 0) return null;

  return (
    <article
      className={clsx(
        'flex flex-col space-y-6 rounded-xl pb-6 pt-8 md:border md:border-solid md:border-neutral-quaternary-alt md:px-4',
        showNewDesign ? 'bg-white' : 'bg-neutral-lighter-alt',
      )}>
      <ProgramTitleCard title={title} detail={headline} size="lg" />
      {programs ? (
        <div className="flex flex-col space-y-2">
          <Text as="body-md" weight="bold">
            Available for
          </Text>
          <div className="flex w-full flex-col flex-wrap space-y-6 sm:flex-row sm:space-x-12 sm:space-y-0">
            {programs.map(({name, icon}, idx) => (
              <div
                className="flex w-fit items-center space-x-2"
                key={`${title}program:${idx}`}>
                <Icon className="h-12 w-12" name={icon} />
                <Text as="body-md" weight="bold" casing="title">
                  {name}
                </Text>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <List
        title="Program Details"
        textClassName="font-normal"
        items={details}
      />
      <List
        title="Eligibility requirements"
        textClassName="font-normal"
        items={requirements}
      />
      <ResourceList resources={sectionResources} />
    </article>
  );
};

export default ProgramSection;
