import {Icon, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import Link from 'next/link';
import React, {useState, FC, Fragment} from 'react';
import AnimateHeight from 'react-animate-height';
import DrugDescription from './DrugDescription';
import {DrugItemHeader} from './SelectDrugPanel/DrugItem';
import {CompareDrugDropdown, ICompareDrugOption} from '@/components';

export interface ITableData {
  headers: IHeader[];
  slugs: string[];
  dropdownOptions: ICompareDrugOption[][];
  descriptions: (string | undefined)[];
  subHeaders: ISubHeader[];
  indication: string;
}
interface IHeader {
  drugName: string;
  genericName: string;
}

interface ISubHeader {
  title: string;
  expandableRows: IExpandableRow[];
}

interface IExpandableRow {
  content: string;
  subRows: {
    text?: string;
    pdpLinkTag?: string;
    html?: string;
  }[];
}

interface TableExpandableRowProps {
  onClick: () => void;
  colSpan: number;
  content: string;
  selected: boolean;
}

const TableExpandableRow: FC<TableExpandableRowProps> = ({
  onClick,
  colSpan,
  content,
  selected,
}) => (
  <tr className='cursor-pointer' onClick={onClick}>
    <td colSpan={colSpan} className='bg-neutral-lighter py-4 pl-4 pr-2'>
      <div className='flex justify-between'>
        <Text className='title-sm' weight='bold'>
          {content}
        </Text>
        <div className='flex h-6 w-6 items-center justify-center'>
          <Icon
            name='ChevronDown'
            className={clsx(
              'flex h-4 w-4 items-center text-theme-primary',
              selected ? 'rotate-180' : 'rotate-0',
            )}
          />
        </div>
      </div>
    </td>
  </tr>
);

interface TableExpandableRowContentProps {
  expandableRow: IExpandableRow;
  isOpen: boolean;
  slugs: string[];
}

const TableExpandableRowContent: FC<TableExpandableRowContentProps> = ({
  expandableRow,
  isOpen,
  slugs,
}) => (
  <tr className='divide-x'>
    {expandableRow.subRows.map((subRow, i) => (
      <td
        key={i}
        className='min-h-1 w-1/2 border-0 border-solid border-neutral-lighter p-0 align-top'>
        <div
          className={clsx('h-full', {
            'delay-500ms bg-neutral-lighter': !isOpen,
          })}>
          <AnimateHeight duration={500} height={isOpen ? 'auto' : 0}>
            <div className='px-6 py-4 text-sm md:text-base'>
              {subRow.text}
              {subRow.html && (
                <span dangerouslySetInnerHTML={{__html: subRow.html}} />
              )}
              {subRow.pdpLinkTag ? (
                <>
                  {subRow.text && '. '}
                  <Link
                    className='text-md font-normal text-blue-600 hover:no-underline'
                    href={`/therapies/${slugs[i]}#${subRow.pdpLinkTag}`}>
                    Learn more
                  </Link>
                  .
                </>
              ) : null}
            </div>
          </AnimateHeight>
        </div>
      </td>
    ))}
  </tr>
);

interface CompareDrugsTableProps {
  data: ITableData;
}

const CompareDrugsTable: FC<CompareDrugsTableProps> = ({data}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const toggleRow = (index: string) => {
    const newExpandedRows = [...expandedRows];
    if (newExpandedRows.includes(index)) {
      newExpandedRows.splice(newExpandedRows.indexOf(index), 1);
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  const isRowCollapsed = (index: string) => expandedRows.includes(index);

  return (
    <table className='w-full border-collapse text-left'>
      <thead>
        <tr className='divide-x'>
          {data.headers.map((tHeader, index) => (
            <th
              className='w-1/2 border-0 border-solid border-neutral-lighter p-0'
              key={'theader:' + index}>
              <DrugItemHeader
                drugName={tHeader.drugName}
                genericName={tHeader.genericName}
                className={
                  index === 0
                    ? 'rounded-tl-xl bg-theme-primary'
                    : 'rounded-tr-xl bg-theme-dark-alt'
                }
              />
            </th>
          ))}
        </tr>
        <tr className='divide-x'>
          {data.dropdownOptions.map((tCell, index) => (
            <th
              className='w-1/2 border-0 border-solid border-neutral-lighter bg-neutral-secondary-alt px-4 py-2.5 md:px-6'
              key={'tCell:' + index}>
              <CompareDrugDropdown
                category={(data.indication as string).split('-').join(' ')}
                selectedLabel={data.headers[index].drugName}
                options={tCell}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className='divide-x'>
          {data.descriptions.map((description) => (
            <td
              key={description}
              className='min-h-1 border-0 border-solid border-neutral-lighter align-top'>
              <DrugDescription text={description} />
            </td>
          ))}
        </tr>
      </tbody>
      {data.subHeaders.map((e, i) => (
        <Fragment key={'subheader:' + i}>
          <thead>
            <tr className='divide-x'>
              <th
                colSpan={1}
                className='w-1/2 border-0 border-solid border-neutral-lighter bg-neutral-primary px-6 py-[9px]'>
                <Text weight='bold' variant='white' size='md'>
                  {e.title}
                </Text>
              </th>
              <th
                colSpan={1}
                className='w-1/2 border-0 border-solid border-neutral-lighter bg-neutral-primary px-6 py-[9px]'
              />
            </tr>
          </thead>
          <tbody>
            {e.expandableRows.map((expandableRow, index) => (
              <React.Fragment key={'expandable:' + index}>
                <TableExpandableRow
                  selected={!isRowCollapsed(`${i}${index}`)}
                  onClick={() => toggleRow(`${i}${index}`)}
                  colSpan={2}
                  content={expandableRow.content}
                />
                <TableExpandableRowContent
                  slugs={data.slugs}
                  expandableRow={expandableRow}
                  isOpen={!isRowCollapsed(`${i}${index}`)}
                />
              </React.Fragment>
            ))}
          </tbody>
        </Fragment>
      ))}
    </table>
  );
};

export default CompareDrugsTable;
