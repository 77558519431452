import {useIsMobile} from '@prescriberpoint/ui';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import {FC, memo} from 'react';
import DosingAndAdmin from './DosingAndAdmin';
import LabelSection from './LabelSection';
import {NewBrandHeader, Section} from '@/components';
import {PdpHeaderV1} from '@/components/PdpHeader';
import PrescriberAI from '@/components/PrescriberAI';
import SignUpForUpdates from '@/components/SignUpForUpdates';
import * as FLAGS from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag, usePharmacyContext} from '@/context';
import {
  useAutoOpenChatBox,
  useCurrentDrug,
  useCurrentSlug,
  useEnhanced,
  useIsCoverageToolAvailable,
  useRenderInIFrame,
  useShowEnhancedSection,
  useShowFixedCTAMenu,
  useUser,
  useIsSectionDisabled,
} from '@/hooks';
import {useIsOncology} from '@/hooks/useIsOncology';
import {PageContentLayout} from '@/layouts';

const CTAMenu = dynamic(() => import('./CTAMenu'));

const FASections = dynamic(() => import('./FASections'));

const MostViewedResources = dynamic(() => import('./MostViewedResources'));

const PatientEducation = dynamic(() => import('./PatientEducation'));
const PeopleAlsoAsk = dynamic(() => import('./PeopleAlsoAsk'));
const PriorAuthorizationSection = dynamic(
  () => import('./PriorAuthorizationSection'),
);
const RequestSample = dynamic(() => import('./RequestSamples/RequestSample'));
const Pharmacy = dynamic(() => import('./Pharmacy'));
const Biomarker = dynamic(() => import('./Biomarker'));
const Reps = dynamic(() => import('./Reps'));
const PubMedNews = dynamic(() => import('./PubMedNews'));
const CoverageRestrictions = dynamic(() => import('./CoverageRestrictions'));

export interface ProductDetailProps {}

const ProductDetail: FC<ProductDetailProps> = () => {
  const {setId} = useCurrentSlug();
  const {isEnhanced, disableAi} = useEnhanced(setId);
  const {isOncologyDrug} = useIsOncology();
  const {logged} = useUser();
  const {drugName, genericName, names} = useCurrentDrug();
  const pdpIsEmbedded = useRenderInIFrame();
  const isMobile = useIsMobile();

  const showAIPPT = useFlag(FLAGS.PPT_AI_COMPONENT);
  const hideChatbotForNoEnhanced = useFlag(FLAGS.HIDE_CHATBOT_FOR_NO_ENHANCED);
  const showMVR = useFlag(FLAGS.MOST_VIEW_RESOURCES_SECTION) && isEnhanced;
  const showPA = useShowEnhancedSection(
    SECTION_IDS.priorAuthorization,
    FLAGS.PRIOR_AUTHORIZATION_SECTION,
  );
  const showPubMed = useFlag(FLAGS.PUB_MED_ARTICLES); // TODO I use only the flag because we need to verify is this is only for enhanced drugs and if we have reosurces
  const showPE = useFlag(FLAGS.PATIENT_EDUCATION_SECTION) && isEnhanced;
  const showRS =
    useShowEnhancedSection(
      SECTION_IDS.requestSamples,
      FLAGS.REQUEST_SAMPLES_SECTION,
    ) && !pdpIsEmbedded;
  const {showPH} = usePharmacyContext();
  const showEF =
    useFlag(FLAGS.BIOMARKER_SECTION) && isOncologyDrug && !pdpIsEmbedded;
  const showR = useFlag(FLAGS.REPS_SECTION) && logged && !pdpIsEmbedded;
  const isFaqsDisabled = useIsSectionDisabled(setId, SECTION_IDS.peopleAlsoAsk);
  const showPAS =
    useFlag(FLAGS.PEOPLE_ALSO_ASK_SECTION) && !pdpIsEmbedded && !isFaqsDisabled;
  const showSignUpDrugUpdates =
    useFlag(FLAGS.SIGNUP_DRUG_UPDATE) &&
    drugName === 'Cibinqo' &&
    !pdpIsEmbedded; // this is only being rendered for Cibinqo;
  const showLS = !pdpIsEmbedded;
  const showHero = !pdpIsEmbedded;
  const showHeaderV2 = useFlag(FLAGS.SHOW_V2_PPT_DESIGN);
  const showCTA = !pdpIsEmbedded && !showHeaderV2;
  const {showCoverageOnPdp} = useIsCoverageToolAvailable();

  useAutoOpenChatBox();

  const showCTAFixedMenu = useShowFixedCTAMenu();

  const Sections = () => (
    <>
      {showHero ? (
        <Section
          id={SECTION_IDS.summary}
          noContainer={showHeaderV2}
          className={clsx('relative', [showCTAFixedMenu ? 'mt-10' : '!py-0'])}>
          {!showHeaderV2 ? <NewBrandHeader /> : null}
          {showR && <Reps />}
          <DosingAndAdmin />
          {showMVR ? <MostViewedResources /> : null}
        </Section>
      ) : null}
      {showCoverageOnPdp ? <CoverageRestrictions /> : null}
      {showAIPPT &&
      !pdpIsEmbedded &&
      (isEnhanced || !hideChatbotForNoEnhanced) &&
      !disableAi ? (
        <PrescriberAI
          defaultExpanded={!isMobile}
          triggerId='aiWC_button'
          drugName={drugName}
          drugsName={names}
          hideHyperlinks
        />
      ) : (
        <>
          <div
            id='ppt-pdp-large-ad-container'
            className='hidden h-auto w-full md:block'>
            <div className='hidden' />
          </div>
          <div
            id='ppt-pdp-medium-ad-container-mobile'
            className='h-auto w-full md:hidden'>
            <div className='hidden' />
          </div>
        </>
      )}
      {showEF ? <Biomarker /> : null}
      {showLS ? <LabelSection /> : null}
      {showRS ? <RequestSample /> : null}
      {showPA ? <PriorAuthorizationSection /> : null}
      {showPH && !pdpIsEmbedded ? <Pharmacy /> : null}
      {<FASections setId={setId} />}
      {showPubMed ? <PubMedNews /> : null}
      {showPE ? <PatientEducation /> : null}
      {showPAS ? <PeopleAlsoAsk /> : null}
    </>
  );

  return (
    <div
      className={clsx('w-full', showHeaderV2 ? 'bg-neutral-lighter-alt' : '')}>
      <PageContentLayout>
        <div
          className={clsx(
            'min-h-page-content bg-neutral-lighter-alt px-4 md:px-0',
            {'pt-6 md:w-[70%] md:bg-white': !showHeaderV2},
            {'px-6 pt-4': pdpIsEmbedded},
          )}>
          {showHeaderV2 ? (
            <PdpHeaderV1 drugName={drugName} genericName={genericName} />
          ) : null}
          {showSignUpDrugUpdates && <SignUpForUpdates />}
          <Sections />
        </div>
        {showCTA ? <CTAMenu /> : null}
      </PageContentLayout>
    </div>
  );
};

export {default as OTCProductDetail} from './OTCProductDetail';
export default memo(ProductDetail);
