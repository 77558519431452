import {Link, Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import DOMPurify from 'isomorphic-dompurify';
import {FC, useMemo, useRef} from 'react';
import AnimateHeight from 'react-animate-height';
import {Section} from '@/components';
import {CEVENT_VIEW_MORE_BTN_CLICKED, DlvViewMoreBtnClicked} from '@/constants';
import {SHOW_V2_PPT_DESIGN} from '@/constants/flags';
import {OTHER_SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';
import {useCurrentSlug} from '@/hooks';
import useViewMore from '@/hooks/useViewMore';
import {useLabel} from '@/queries';
import {customEvent, proccessSectionId} from '@/utils/gtm';
import {
  removeAllHTMLElementInStr,
  removeHeaderNumbersAndAnchors,
  formatDosageHTML,
  removeTableWidthAttribute,
} from '@/utils/string';

const DEFAULT_HEIGHT = 150;

interface IDosingAndAdmingProps {}

const DosingAndAdmin: FC<IDosingAndAdmingProps> = () => {
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const content = product?.label.highlights.dosageAndAdministration ?? '';
  const containerRef = useRef<HTMLDivElement>(null);
  const showNewDesign = useFlag(SHOW_V2_PPT_DESIGN);
  const {height, toggleHeight, showClampButton, textClamped} = useViewMore(
    containerRef,
    DEFAULT_HEIGHT,
  );

  const adjustHeight = (e: any) => {
    e.preventDefault();
    if (textClamped) {
      customEvent<DlvViewMoreBtnClicked>(CEVENT_VIEW_MORE_BTN_CLICKED, {
        resourceSection: proccessSectionId(
          OTHER_SECTION_IDS.dosingAndAdmiministration,
        ),
      });
    }
    toggleHeight();
  };

  const sanitizedText = useMemo(() => {
    let safeContent = removeHeaderNumbersAndAnchors(
      removeAllHTMLElementInStr(content, 'h1'),
    ).replace(/\s{9}/g, '');

    safeContent = removeTableWidthAttribute(safeContent);

    return DOMPurify.sanitize(formatDosageHTML(safeContent));
  }, [content]);

  const showGradient = textClamped && showClampButton;

  if (showNewDesign) {
    return (
      <Section
        id={OTHER_SECTION_IDS.dosingAndAdmiministration}
        data-testid={OTHER_SECTION_IDS.dosingAndAdmiministration}
        noContainer
        title='Dosage & Administration'>
        <div
          className={clsx(
            'relative flex flex-col space-y-4 rounded-lg bg-white shadow-lg',
            showGradient && 'pb-4',
          )}>
          <AnimateHeight height={height} duration={500}>
            <div
              ref={containerRef}
              dangerouslySetInnerHTML={{
                __html: sanitizedText,
              }}
              className={clsx('drug-label-table  overflow-x-auto px-4 pt-4', [
                'text-neutral-primary [&>div>:first-child]:mt-0',
                '[&>div>label>label>label]:inline-block [&>div>label>label>label]:pl-5',
              ])}
            />
          </AnimateHeight>
          <div
            className={clsx(
              'flex w-full items-end justify-end px-4 pb-4 transition-all',
              showGradient &&
                'absolute bottom-0 h-full bg-gradient-to-b from-transparent to-white',
            )}>
            {showClampButton ? (
              <Link
                aria-expanded={!textClamped}
                onClick={adjustHeight}
                className='bg-white'
                label={textClamped ? 'View more' : 'View less'}
              />
            ) : null}
          </div>
        </div>
      </Section>
    );
  }

  return (
    <div
      className='flex flex-col space-y-2 py-4'
      id={OTHER_SECTION_IDS.dosingAndAdmiministration}
      data-testid={OTHER_SECTION_IDS.dosingAndAdmiministration}>
      <Text className='text-center' as='headline-sm' tag='h2'>
        Dosage & Administration
      </Text>
      <div
        className={clsx(
          'relative flex flex-col space-y-4 rounded-lg bg-white shadow-lg',
          showGradient && 'pb-4',
        )}>
        <AnimateHeight height={height} duration={500}>
          <div
            ref={containerRef}
            dangerouslySetInnerHTML={{
              __html: sanitizedText,
            }}
            className={clsx('drug-label-table  overflow-x-auto px-4 pt-4', [
              'text-neutral-primary [&>div>:first-child]:mt-0',
              '[&>div>label>label>label]:inline-block [&>div>label>label>label]:pl-5',
            ])}
          />
        </AnimateHeight>
        <div
          className={clsx(
            'flex w-full items-end justify-end px-4 pb-4 transition-all',
            showGradient &&
              'absolute bottom-0 h-full bg-gradient-to-b from-transparent to-white',
          )}>
          {showClampButton ? (
            <Link
              aria-expanded={!textClamped}
              onClick={adjustHeight}
              label={textClamped ? 'View more' : 'View less'}
              className='bg-white'
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const DosingAndAdminWrapper: FC<IDosingAndAdmingProps> = () => {
  const {setId} = useCurrentSlug();
  const {data: product} = useLabel(setId);
  const content = product?.label?.highlights?.dosageAndAdministration;
  return content ? <DosingAndAdmin /> : null;
};

export default DosingAndAdminWrapper;
