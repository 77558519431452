import {QueryClient, useQuery} from '@tanstack/react-query';
import {IPrefetchedQuery} from './prefetchQueries';
import {
  ALGOLIA_PHARMACIES_INDEX,
  TEMP_PDP_ALGOLIA_PHARMACIES_INDEX,
} from '@/constants/global';
import {useCurrentSlug} from '@/hooks';
import {PharmacyDetailsDto, PharmacyResultDto} from '@/models';
import {searchClient} from '@/services/algolia';
import {buildPlainAddress} from '@/utils/address';

export async function getPharmacy(
  pharmacySlug: string,
  setId: string,
  useTempIndex?: boolean,
): Promise<PharmacyDetailsDto | null> {
  let data: PharmacyResultDto | null = null;

  if (!pharmacySlug) return data;

  try {
    //TODO: this should be undone
    const index = searchClient.initIndex(
      useTempIndex
        ? TEMP_PDP_ALGOLIA_PHARMACIES_INDEX
        : ALGOLIA_PHARMACIES_INDEX,
    );

    const queryParams = {
      filters: `slug:${pharmacySlug}`,
      attributesToRetrieve: ['*', '-_highlightResult', '-_geoloc'],
    };

    let response = await index.search('', queryParams);

    data = response.hits[0] as PharmacyResultDto;
  } catch (e) {
    console.error(e);
  }
  if (!data) return null;

  function getDistribution() {
    if (data?.exclusive_brands?.findIndex((e) => e.set_id === setId) !== -1)
      return 'exclusive';
    if (data?.limited_brands?.findIndex((e) => e.set_id === setId) !== -1)
      return 'limited';
    return 'unknown';
  }

  return {
    name: data.name!,
    patient_services: data.patient_services || [],
    pharma_services: data.pharma_services || [],
    contracted_plans: data.contracted_plans || [],
    objectID: data.objectID!,
    description: data.description || '',
    phone: data.phone || '',
    email: data.email || '',
    payer_services: data.payer_services || [],
    distribution: getDistribution(),
    address: buildPlainAddress(data.address!),
    logo: `/api/${data.slug}/logo`,
    limited_brands: data.limited_brands || [],
    exclusive_brands: data.exclusive_brands || [],
  };
}

export function usePharmacy(
  pharmacySlug: string,
  options = {},
  useTempIndex?: boolean,
) {
  const {setId} = useCurrentSlug();
  return useQuery<PharmacyDetailsDto | null>({
    queryKey: ['pharmacySlug', pharmacySlug],
    queryFn: () => getPharmacy(pharmacySlug, setId, useTempIndex),
    refetchOnMount: false,
    ...options,
  });
}

export function getPharmacyPrefetchQuery(
  pharmacySlug: string,
  setId: string,
  useTempIndex?: boolean,
): IPrefetchedQuery {
  return {
    queryKey: ['pharmacySlug', pharmacySlug],
    getFn: async () => await getPharmacy(pharmacySlug, setId, useTempIndex),
  };
}

export async function fetchQueryPharmacy(
  pharmacySlug: string,
  setId: string,
  queryClient: QueryClient,
  useTempIndex?: boolean,
): Promise<PharmacyDetailsDto | null> {
  return await queryClient.fetchQuery({
    queryKey: ['pharmacySlug', pharmacySlug],
    queryFn: async () => await getPharmacy(pharmacySlug, setId, useTempIndex),
  });
}
