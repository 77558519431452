import {Text, FormInput} from '@prescriberpoint/ui';
import {FC, useEffect, useState} from 'react';
import {useUserAgentContext} from '@/context';

export interface IHeaderProps {
  showSortSelect: boolean;
  sortBy: string;
  setSortBy: (value: string) => void;
  lastUpdated: string;
  title: string;
  description: string;
}

const SelectInput = FormInput.Select;

const Header: FC<IHeaderProps> = ({
  showSortSelect,
  sortBy,
  setSortBy,
  lastUpdated,
  title,
  description,
}) => {
  const {isMobileOrTablet} = useUserAgentContext();
  const [lastUpdatedStr, setLastUpdatedStr] = useState('');

  useEffect(() => {
    setLastUpdatedStr(lastUpdated);
  }, [lastUpdated]);

  return (
    <div className="mb-4 mt-4 items-end md:mb-10 md:mt-8 md:flex md:space-x-9">
      <div className="flex flex-col space-y-2">
        <Text as="body-sm" casing="caps" variant="secondary" weight="medium">
          Last verified: {lastUpdatedStr}
        </Text>
        <Text
          tag="h1"
          as={isMobileOrTablet ? 'title-lg' : 'headline-lg'}
          weight="bold">
          {title}
        </Text>
        <Text as="body-md" weight="regular">
          {description}
        </Text>
      </div>
      {showSortSelect && (
        <div className="pt-2 md:pt-0 [&>*]:md:w-[172px]">
          <SelectInput
            errorMessage={null}
            containerClassName="flex justify-end md:[&>*]:justify-end [&>*>*>*>svg]:text-theme-primary"
            id="select"
            label="Sort By"
            name="sortBy"
            options={[
              {
                key: 'asc',
                text: 'A - Z',
              },
              {
                key: 'desc',
                text: 'Z - A',
              },
            ]}
            placeholder="A - Z"
            value={sortBy}
            onChange={setSortBy}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
