/* eslint-disable import/named */
import {Text} from '@prescriberpoint/ui';
import {Link} from 'react-scroll';
import {alphabet} from '@/utils';

interface JumpingLinksProps {}

export default function JumpingLinks({}: JumpingLinksProps) {
  return (
    <div className="flex flex-wrap space-x-2">
      {alphabet.map((e) => (
        <Link
          key={e}
          to={e}
          smooth="easeInOutQuart"
          duration={1000}
          className="hover:cursor-pointer">
          <Text
            as="title-md"
            casing="title"
            variant="base"
            className="hover:!text-theme-dark-alt hover:underline active:!text-neutral-dark">
            {e}
          </Text>
        </Link>
      ))}
    </div>
  );
}
