import {Text} from '@prescriberpoint/ui';
import clsx, {ClassValue} from 'clsx';
import {FC, useState} from 'react';
import {useUserAgentContext} from '@/context';

interface DrugDescriptionProps {
  text?: string;
  className?: ClassValue;
}

const DrugDescription: FC<DrugDescriptionProps> = ({text, className}) => {
  const [showMoreButton, setShowButton] = useState(!!text);
  const {isMobileOrTablet} = useUserAgentContext();

  const clampText = (paragraph?: string) => {
    if (!paragraph) return '';
    const clampedText = paragraph.slice(0, 180);
    const lastSpace = clampedText.lastIndexOf(' ');
    return clampedText.slice(0, lastSpace) + '...';
  };

  return (
    <div className={clsx('flex h-full flex-col px-4 py-4 md:px-6', className)}>
      <span className="tracking-small inline-block w-fit rounded-full bg-theme-tertiary px-2.5 py-0.5 text-sm font-bold text-white">
        Prescription Only
      </span>
      <article className="relative mt-4">
        <Text
          className="inline-block text-neutral-darker"
          size={isMobileOrTablet ? 'sm' : 'md'}
          weight="regular">
          {showMoreButton ? clampText(text) : text}
          {showMoreButton && (
            <button onClick={() => setShowButton(false)}>
              <Text
                className="text-blue-600 underline hover:no-underline"
                weight="regular"
                size="md">
                more
              </Text>
            </button>
          )}
        </Text>
      </article>
    </div>
  );
};

export default DrugDescription;
