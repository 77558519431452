import {FC} from 'react';
import FAHeader from './FAHeader';
import FAPrograms from './FAPrograms';
import ProgramSection from './ProgramSection';
import {
  COPAY_PROGRAM,
  PATIENT_ASSISTANCE_PROGRAM,
  FOUNDATION_PROGRAM,
} from '@/constants/financialAssistance';
import {FINANCIAL_ASSISTANCE_AFFORDABILITY} from '@/constants/flags';
import {SECTION_IDS} from '@/constants/sectionIDs';
import {useFlag} from '@/context';
import {FINANCIAL_ASSISTANCE_SECTION_IDS} from '@/data';
import {useCurrentDrug} from '@/hooks';
import {EnhancedContentLinkDto, IEnhancedContent} from '@/models';
import {
  getCopayProgramDetails,
  getPatientAssistancePrice,
  getPatientProgramDetails,
  getCopayPrice,
  getFoundationPrograms,
} from '@/utils';

const {financialAssistance: sectionId} = SECTION_IDS;

export interface IFinancialAssistanceProps {
  enhancedContent?: IEnhancedContent;
  resources: EnhancedContentLinkDto[];
  retailPrice?: number;
}

const FinancialAssistance: FC<IFinancialAssistanceProps> = ({
  resources,
  enhancedContent,
  retailPrice,
}) => {
  const {drugName} = useCurrentDrug();
  const showPrograms = useFlag(FINANCIAL_ASSISTANCE_AFFORDABILITY);

  const {
    copaySavingsCardProgram,
    foundationAssistanceProgram,
    patientAssistanceProgram,
    financialAssistanceProgram,
  } = enhancedContent ?? {};

  const copayProgramSection = copaySavingsCardProgram ? (
    <ProgramSection
      title={COPAY_PROGRAM.name}
      headline={getCopayPrice(copaySavingsCardProgram)}
      programs={COPAY_PROGRAM.programs}
      details={getCopayProgramDetails(copaySavingsCardProgram)}
      resources={resources}
      sectionId={FINANCIAL_ASSISTANCE_SECTION_IDS.copay}
    />
  ) : null;
  const patientAssistanceProgramSection = patientAssistanceProgram ? (
    <ProgramSection
      title={PATIENT_ASSISTANCE_PROGRAM.title}
      headline={getPatientAssistancePrice(patientAssistanceProgram!)}
      programs={PATIENT_ASSISTANCE_PROGRAM.programs}
      details={getPatientProgramDetails(patientAssistanceProgram)}
      resources={resources}
      sectionId={FINANCIAL_ASSISTANCE_SECTION_IDS.copay}
      // Copay is an equivalent of Patient Assistance Program
    />
  ) : null;

  return (
    <article
      className='flex w-full flex-col space-y-8'
      id={sectionId}
      data-testid={sectionId}>
      <FAHeader drugName={drugName} price={retailPrice} />
      {showPrograms ? <FAPrograms retailPrice={retailPrice} /> : null}
      <div className='flex flex-col space-y-6'>
        {/* This is implemented this way in order to not break anything inside PDP,
          on the near future, copayProgramSection and foundationProgramSection
          will be removed from enhanced content and only financialAssistanceProgram
          will be used
        */}
        {financialAssistanceProgram ? (
          financialAssistanceProgram.programType === 'Patient Assistance' ? (
            patientAssistanceProgramSection
          ) : (
            copayProgramSection
          )
        ) : (
          <>
            {copayProgramSection}
            {patientAssistanceProgramSection}
          </>
        )}
        {foundationAssistanceProgram ? (
          <ProgramSection
            title={FOUNDATION_PROGRAM.name}
            headline={FOUNDATION_PROGRAM.price}
            programs={getFoundationPrograms(foundationAssistanceProgram)}
            requirements={foundationAssistanceProgram.requirements}
            resources={resources}
            sectionId={FINANCIAL_ASSISTANCE_SECTION_IDS.foundation}
          />
        ) : null}
      </div>
    </article>
  );
};
export default FinancialAssistance;
