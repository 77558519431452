import {Text} from '@prescriberpoint/ui';
import clsx from 'clsx';
import * as React from 'react';
import FeatureCard from './FeaturesCard';
import {useUserAgentContext} from '@/context';
import {HCP_FEATURES, REP_FEATURES} from '@/data/signupFeatures';

interface IFeaturesProps {
  isRepSignup?: boolean;
}

const Features: React.FunctionComponent<IFeaturesProps> = ({
  isRepSignup = false,
}) => {
  const {isMobileOrTablet} = useUserAgentContext();

  const features = !isRepSignup ? HCP_FEATURES : REP_FEATURES;

  return (
    <>
      {isMobileOrTablet && (
        <div className="flex flex-col items-start justify-start space-y-4 pb-10">
          <div className="flex w-full flex-col items-center justify-start space-y-2 text-center">
            <Text as="body-sm" weight="bold" casing="caps" variant="secondary">
              free for HCP&apos;s
            </Text>
            <Text as="headline-sm" variant="white" weight="bold">
              Stop browsing confusing drug & insurance websites
            </Text>
          </div>
          <Text
            as="body-md"
            weight="bold"
            variant="white"
            className="text-center text-opacity-60">
            We research, prioritize, and organize the most meaningful resources
            for all things drug-related.
          </Text>
        </div>
      )}

      <div
        className={clsx('flex w-full overflow-x-auto md:flex-wrap', {
          'md:max-w-[600px]': isRepSignup,
        })}>
        {features.map((feature, idx) => (
          <FeatureCard key={idx} {...feature} />
        ))}
      </div>
    </>
  );
};
export default Features;
