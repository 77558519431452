import {
  Icon,
  Text,
  toSnakeCase,
  Button,
  customEvent,
} from '@prescriberpoint/ui';
import {FC, useState} from 'react';
import {useEffectOnce} from 'usehooks-ts';
import ResourceItem from './ResourceItem';
import {CEVENT_VIEW_ALL_FORMS, DlvViewAllForms} from '@/constants';
import {useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';
import {ICarrier} from '@/models';
import {capitalize} from '@/utils';

export interface IPayerFormCardProps {
  payer: ICarrier;
}

const PayerFormCard: FC<IPayerFormCardProps> = ({payer}) => {
  const [buttonText, setButtonText] = useState(
    `View ${capitalize(payer.name)} Forms`,
  );
  const {slug, drugName} = useCurrentDrug();
  const {isMobileOrTablet} = useUserAgentContext();
  const path = `/therapies/${slug}/prior-authorization-forms/${payer.id}`;

  useEffectOnce(() => {
    setButtonText('View All Forms');
  });

  const fireCustomEvent = () => {
    customEvent<DlvViewAllForms>(CEVENT_VIEW_ALL_FORMS, {
      drugName,
      payer: payer.name,
    });
  };

  return (
    <div className="flex flex-col space-y-2 rounded-lg bg-gradient-to-b from-neutral-light p-4 md:w-[896px]">
      <div className="flex justify-between">
        <div className="flex items-center space-x-2">
          <div
            className={
              'flex h-10 w-10 items-center justify-center rounded-md bg-neutral-lighter'
            }>
            <div className="flex h-full w-full items-center justify-center rounded-sm bg-white">
              <Icon name="Insurance" className="h-6 w-6 text-white" />
            </div>
          </div>
          <Text as="title-lg" tag="h3" size="lg" weight="semibold">
            {payer.name}
          </Text>
        </div>
        {!isMobileOrTablet && (
          <Button
            asLink
            href={path}
            onClick={fireCustomEvent}
            id={`view_${toSnakeCase(payer.name)}_forms`}
            className={isMobileOrTablet ? 'w-full' : 'w-fit'}>
            {buttonText}
          </Button>
        )}
      </div>
      <div className="flex flex-col space-y-2">
        {payer.documents?.map((resource, i) => (
          <ResourceItem
            key={resource.id + i}
            resource={resource}
            path={`${path}?resourceId=${i}`}
          />
        ))}
      </div>
      {isMobileOrTablet && (
        <Button
          asLink
          href={path}
          id={`view_${toSnakeCase(payer.name)}_forms`}
          className="capitalize">
          {`View ${capitalize(payer.name)} Forms`}
        </Button>
      )}
    </div>
  );
};

export default PayerFormCard;
