import {Text} from '@prescriberpoint/ui';
import {FC} from 'react';
import {useUserAgentContext} from '@/context';
import {useCurrentDrug} from '@/hooks';

interface HeaderProps {
  lastUpdated: string;
  payerName: string;
}

const Header: FC<HeaderProps> = ({lastUpdated, payerName}) => {
  const {drugName} = useCurrentDrug();

  const pageDescription = `Updated ${payerName} Rx prior authorization form for ${drugName} by region & plan`;

  const {isMobileOrTablet} = useUserAgentContext();

  return (
    <div className="flex w-full flex-col ">
      <Text as="body-sm" casing="caps" variant="secondary" weight="medium">
        Last verified: {lastUpdated}
      </Text>
      <Text
        tag="h1"
        as={isMobileOrTablet ? 'title-lg' : 'headline-lg'}
        weight="bold"
        casing="title">
        {drugName} | {payerName} | Prior Authorization Form
      </Text>
      <Text as="body-md" casing="title" weight="regular">
        {pageDescription}
      </Text>
    </div>
  );
};

export default Header;
