import {Text} from '@prescriberpoint/ui';
import {FINANCIAL_ASSISTANCE_DETAILS} from '@/constants/financialAssistance';
import {replaceVariablesInStr, formatUSD} from '@/utils';

interface FAHeaderProps {
  drugName: string;
  price?: number;
}

const {section, title, drugDetail, eligibilityDetail} =
  FINANCIAL_ASSISTANCE_DETAILS;

const FAHeader = ({drugName, price}: FAHeaderProps) => {
  const regex = /\s\$[0-9]*\.[0-9]*\s/;
  const [detailBeforePrice, detailAfterPrice] = replaceVariablesInStr(
    drugDetail,
    {drugName},
  ).split(regex);

  return (
    <article className="flex w-full justify-center px-4 pt-8 sm:pt-14">
      <div className="flex w-full max-w-[680px] flex-col items-center space-y-6 text-center">
        <Text
          tag="h3"
          className="text-blue-700"
          casing="caps"
          weight="semibold">
          {section}
        </Text>
        <Text tag="h1" as="headline-lg" casing="title">
          {title}
        </Text>
        {price ? (
          <Text as="title-lg" weight="semibold">
            {detailBeforePrice}
            <strong>{' ' + formatUSD(Math.round(price)) + ' '}</strong>
            {detailAfterPrice}
          </Text>
        ) : null}
        <Text as="body-md" className="text-left" weight="semibold">
          {replaceVariablesInStr(eligibilityDetail, {drugName})}
        </Text>
      </div>
    </article>
  );
};

export default FAHeader;
